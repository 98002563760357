import { Etar } from '../entity/etar-model';
import { Client } from './client-model';
import { Establishment, EstablishmentEditOnUser, EtarEditOnUser } from './establishment-model';
import { ManagingEntity } from './managingEntity-model';

export class RecoveryPassword {
  constructor(public email?: string) {}
}

export class UpdatePassword {
  constructor(public password: string = '', public token: string = '') {}
}

export class User {
  constructor(
    public id: number = 0,
    public notificationsDto?: {
      id?: number;
      userCreation?: boolean;
    },
    public role?: string,
    public roleName?: string,
    public validated?: boolean,
    public clientId?: number,
    public client?: Client,
    public etarsDto?: Etar[],
    public managingEntityDto?: ManagingEntity,
    public name?: string,
    public email?: string,
    public password?: string,
    public establishments?: Establishment[],
    public accountCreation?: boolean,
    public accountActivation?: boolean,
    public documentRequest?: boolean,
    public orderStatusChanged?: boolean,
    public contractAndRescheduleConfirmation?: boolean,
    public approximationDeliveryDate?: boolean,
    public reschedulingUnavailabilityEtar?: boolean,
    public visible?: boolean,
    public userType?: number,
    public userStatus?: any,
    public isUpdateEmail?: boolean,

    //NOTIFICATIONS ETAR E EG

    public newServiceProvisionContracts?: boolean,
    public assessTheNeedToRequestAnAnalyticalBulletin?: boolean,
    public approximationOfScheduledDeliveryDate?: boolean
  ) {}
}

// private Set<SingleDeliveryOrder> singleDeliveryOrdersDto = new HashSet<>();
// private Set<RegularDeliveryOrder> regularDeliveryOrderDto = new HashSet<>();

export class Login {
  constructor(public username: string, public password: string) {}
}

export class UserNotificationsEdit {
  constructor(
    public contractAndRescheduleConfirmation?: boolean,
    public approximationDeliveryDate?: boolean,
    public userType?: number,

    //Notifications etar e eg

    public newServiceProvisionContracts?: boolean,
    public assessTheNeedToRequestAnAnalyticalBulletin?: boolean,
    public approximationOfScheduledDeliveryDate?: boolean
  ) {}
}

export class UserEdit {
  constructor(
    public clientId: number | unknown = 0,
    public role: string | unknown = '',
    public roleName: string | unknown = '',
    public name: string | unknown = '',
    public email: string | unknown = '',
    public establishments: EstablishmentEditOnUser[] | unknown = [],
    public etarsDto: EtarEditOnUser[] | unknown = [],

    public visible?: boolean,
    public id: number = 0,
    public isUpdateEmail?: boolean
  ) {}
}

export class CreateUserBackOffice {
  constructor(
    public name: string | unknown = '',
    public email: string | unknown = '',
    public roleName: string | unknown = '',
    public role: string | unknown = '',
    public userType: number | unknown = 0,
    public managingEntityDto?: ManagingEntity,
    public etarsDto: EtarEditOnUser[] | unknown = [],
    public visible?: boolean,
    public clientId: number = 1000
  ) {}
}

export class UserEditStatus {
  constructor(public userStatus: number | unknown = 0) {}
}

export interface UserTable extends User {
  isShow?: boolean;
  etarString?: string;
}

export interface ProfileInformation {
  type: string;
  name: string;
  profile: string;
  defaultLink: string[];
}
