import { Login, ProfileInformation, User } from '@Models/client/user-model';
import { UsersService } from '@Services/client-services/user/users.service';
import { Roles } from '@Shared/global-variable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.API_URL;
  public userSubject = new BehaviorSubject<User | null>(null);
  public isAuthenticated = new BehaviorSubject<boolean>(false);
  public role = '';
  public profileInformation = new BehaviorSubject<ProfileInformation | null>(null);

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    private userService: UsersService,
    private deviceService: DeviceDetectorService
  ) {
    if (!this.hasToken()) {
      this.logout();
    } else {
      this.isAuthenticated.next(this.hasToken());
      this.userSubject.next(this.getCurrentUser());
    }
  }

  login(user: Login) {
    const formData = new FormData();
    formData.append('email', user.username);
    formData.append('password', user.password);

    this.http
      .post(`${this.apiUrl}/j_security_check`, formData, {
        responseType: 'text'
      })
      .pipe()
      .subscribe({
        next: () => {
          this.loadingUserAndTokenRoutine();
        },
        error: () => {
          this.loadingUserAndTokenRoutine();
        }
      });

    return true;
  }

  loadingUserAndTokenRoutine() {
    if (!this.isAuthenticated.value) {
      this.userService.getUserById().subscribe(user => {
        this.isAuthenticated.next(true);
        this.userSubject.next(user);
        this.role = user.role!;
        localStorage.setItem('ADP_user', JSON.stringify(user));
        localStorage.setItem('userId', user.id.toString());
        localStorage.setItem('role', user.role!);
        localStorage.setItem('name', user.name!);
        localStorage.setItem('compa', user.client!.name!);
        localStorage.setItem('client', user.clientId!.toString());

        if (user.role === 'ETAR_ADMIN' || user.role === 'EG_ADMIN') {
          localStorage.setItem('entityMe', user.managingEntityDto!.id!.toString());
          localStorage.setItem('entityName', user.managingEntityDto!.name!);
        }

        this.redirectCurrentRole();
      });
    } else {
      const storageProfile = localStorage.getItem('profile');
      if (storageProfile) {
        this.profileInformation.next(JSON.parse(storageProfile));
        this.redirectCurrentRole();
      }
    }
  }

  getCurrentUserEntity() {
    if (this.userSubject.value?.managingEntityDto) {
      return {
        entityId: this.userSubject.value.managingEntityDto.id,
        entityName: this.userSubject.value.managingEntityDto.name
      };
    }
    return null;
  }

  getCurrentUser() {
    const storageUser = localStorage.getItem('ADP_user');
    return storageUser ? JSON.parse(storageUser) : null;
  }

  getToken(): string {
    return this.cookieService.get('quarkus-credential');
  }

  hasToken(): boolean {
    return this.cookieService.check('quarkus-credential');
  }

  getUserId(): number {
    return Number(localStorage.getItem('userId'));
  }

  getClientId(): number {
    return Number(localStorage.getItem('client'));
  }

  getAuthorizationToken() {
    const token = localStorage.getItem('token');
    return token;
  }

  getRole() {
    return localStorage.getItem('role') ?? null;
  }

  redirectCurrentRole() {
    const currentUser = this.userSubject.value;
    const profile: ProfileInformation = {
      name: currentUser?.name ?? '',
      type: '',
      profile: 'client',
      defaultLink: []
    };
    switch (this.getRole()) {
      case Roles.CLIENT_ADMIN:
      case Roles.CLIENT_ADMIN_ESTAB:
      case Roles.CLIENT_CONSULTA:
        profile.type = currentUser?.client?.name ?? '';
        profile.profile = 'client';
        profile.defaultLink = ['/cliente', 'dashboard'];
        break;
      case Roles.ETAR_ADMIN:
      case Roles.EG_ADMIN:
        profile.type = currentUser?.managingEntityDto?.name ?? '';
        profile.profile = 'entity';

        if (this.deviceService.isMobile()) {
          profile.defaultLink = ['/mobile'];
        } else {
          if (this.getRole() === Roles.EG_ADMIN) {
            profile.defaultLink = ['/entidade-gestora', 'dashboard'];
          } else if (this.getRole() === Roles.ETAR_ADMIN) {
            if (currentUser?.managingEntityDto?.id)
              profile.defaultLink = [
                '/entidade-gestora',
                'dashboard',
                'etar',
                currentUser?.managingEntityDto?.id.toString()
              ];
          }
        }
        break;
      case Roles.ADP_ADMIN:
      case Roles.SUPER_ADMIN:
        profile.name = 'ADP Valor';
        profile.type = currentUser?.managingEntityDto?.name ?? '';
        profile.profile = 'backoffice';
        profile.defaultLink = ['/backoffice', 'dashboard'];
        break;
    }

    this.profileInformation.next(profile);
    this.router.navigate(profile.defaultLink);
    localStorage.setItem('profile', JSON.stringify(profile));
  }

  isAdpAdmin() {
    return this.getRole() === Roles.ADP_ADMIN;
  }

  isSuperAdmin() {
    return this.getRole() === Roles.SUPER_ADMIN;
  }

  isEgAdmin() {
    return this.getRole() === Roles.EG_ADMIN;
  }

  isEtarAdmin() {
    return this.getRole() === Roles.ETAR_ADMIN;
  }

  isClient() {
    return this.getRole() === Roles.CLIENT_ADMIN;
  }

  isClientEstablishment() {
    return this.getRole() === Roles.CLIENT_ADMIN_ESTAB;
  }

  logout() {
    this.role = '';
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll('/');
    this.isAuthenticated.next(false);
    this.profileInformation.next(null);
    this.userSubject.next(null);
    this.router.navigate(['/auth', 'sign-in']);
  }
}
