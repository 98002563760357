import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { RoleGuard } from '../core/guards/role.guard';
import { SignInGuard } from '../core/guards/sign-in.guard';
import { PagesComponent } from './pages.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [SignInGuard, AuthGuard],
    canActivateChild: [RoleGuard],
    children: [
      {
        path: 'backoffice',
        data: {
          expectedRole: ['ADP_ADMIN', 'SUPER_ADMIN']
        },
        loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule)
      },
      {
        path: 'entidade-gestora',
        data: {
          expectedRole: ['EG_ADMIN', 'ETAR_ADMIN']
        },
        loadChildren: () =>
          import('./managing-entity/managing-entity.module').then(m => m.ManagingEntityModule)
      },
      {
        path: 'cliente',
        data: {
          expectedRole: ['CLIENT_ADMIN_ESTAB', 'CLIENT_ADMIN', 'CLIENT_CONSULTA']
        },
        loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
      },
      {
        path: 'pedido',
        data: {
          expectedRole: ['ADP_ADMIN', 'SUPER_ADMIN', 'CLIENT_ADMIN_ESTAB', 'CLIENT_ADMIN', 'CLIENT_CONSULTA']
        },
        loadChildren: () =>
          import('../modules/create-new-order/create-new-order.module').then(m => m.CreateNewOrderModule)
      }
    ]
  },
  {
    path: 'auth',
    data: {
      expectedRole: ['ALL']
    },
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'mobile',
    canActivate: [SignInGuard],
    canActivateChild: [RoleGuard, AuthGuard],
    data: {
      expectedRole: ['EG_ADMIN', 'ETAR_ADMIN']
    },
    loadChildren: () => import('./mobile/mobile.module').then(m => m.MobileModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {}
