import { LoaderService } from '@Services/loader/loader.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url !== `${environment.API_URL}/j_security_check`) {
      this.loaderService.setLoading(true, request.url);
    }
    return next.handle(request).pipe(
      map<HttpEvent<any>, any>((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loaderService.setLoading(false, request.url);
        }
        return event;
      })
    );
  }
}
