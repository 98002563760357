import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContractHistoricPipePipe } from './pipe/contract/contract-historic-pipe.pipe';
import { ContractScheduleStatusPipe } from './pipe/contract/contract-schedule-status.pipe';
import { ContractStatusPipe } from './pipe/contract/contract-status.pipe';
import { ContractOrderHandlingDecisionTypePipe } from './pipe/contract/contrat-order-handling-decision-type.pipe';
import { StatusDocumentPipe } from './pipe/documents/status-document.pipe';
import { TypeDocumentPipe } from './pipe/documents/type-document.pipe';
import { LocalDateTimePipe } from './pipe/local-date-time.pipe';
import { PeriodicPipe } from './pipe/periodic.pipe';
import { RolePipe } from './pipe/role.pipe';
import { SafePipe } from './pipe/safe.pipe';
import { ClientStatusPipe } from './pipe/status/client-status.pipe';
import { DeliveryStatusPipe } from './pipe/status/delivery-status.pipe';
import { UserStatusPipe } from './pipe/status/user-status.pipe';
import { StringShorterPipe } from './pipe/string-shorter.pipe';
import { WeekDayPipe } from './pipe/week-day.pipe';

@NgModule({
  declarations: [
    ClientStatusPipe,
    ContractHistoricPipePipe,
    ContractOrderHandlingDecisionTypePipe,
    ContractScheduleStatusPipe,
    ContractStatusPipe,
    DeliveryStatusPipe,
    LocalDateTimePipe,
    PeriodicPipe,
    RolePipe,
    SafePipe,
    StatusDocumentPipe,
    StringShorterPipe,
    TypeDocumentPipe,
    UserStatusPipe,
    WeekDayPipe
  ],
  imports: [CommonModule],
  exports: [
    ClientStatusPipe,
    ContractHistoricPipePipe,
    ContractOrderHandlingDecisionTypePipe,
    ContractScheduleStatusPipe,
    ContractStatusPipe,
    DeliveryStatusPipe,
    LocalDateTimePipe,
    PeriodicPipe,
    RolePipe,
    SafePipe,
    StatusDocumentPipe,
    StringShorterPipe,
    TypeDocumentPipe,
    UserStatusPipe,
    WeekDayPipe
  ]
})
export class PipeModule {}
