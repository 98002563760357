import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, UrlTree } from '@angular/router';
import { AuthService } from '@Services/auth/auth.service';
import { Roles } from '@Shared/global-variable';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivateChild {
  constructor(private authService: AuthService) {}

  canActivateChild(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedRole = route.data['expectedRole'];
    const currentRole = this.authService.getRole();
    if (expectedRole === Roles.ALL || expectedRole.includes(currentRole)) {
      return true;
    } else {
      this.authService.redirectCurrentRole();
      return false;
    }
  }
}
