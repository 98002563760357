import { ProfileInformation, User } from '@Models/client/user-model';
import { AuthService } from '@Services/auth/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, Subject } from 'rxjs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  user!: User | null;
  userType!: string;
  isResponsiveMenuOpen = false;
  layoutLanguage = 1;
  currentProfile$!: Observable<ProfileInformation | null>;
  private unSubscribeLang$ = new Subject<void>();
  constructor(private authService: AuthService, private translocoService: TranslocoService) {}

  ngOnDestroy(): void {
    this.unSubscribeLang$.next();
    this.unSubscribeLang$.complete();
  }

  ngOnInit(): void {
    this.currentProfile$ = this.authService.profileInformation;
    this.user = this.authService.userSubject.value;

    const lang = localStorage.getItem('adp_lang');
    if (lang === 'PT') {
      this.setLanguage(1);
    } else if (lang === 'EN') {
      this.setLanguage(2);
    }
  }

  menuResponsive(): void {
    this.isResponsiveMenuOpen = !this.isResponsiveMenuOpen;
  }

  logout() {
    this.authService.logout();
  }

  clearBrowserCache() {
    localStorage.removeItem('continuation');
    localStorage.removeItem('step1');
    localStorage.removeItem('productType');
    localStorage.removeItem('perDay');
    localStorage.removeItem('idStep2');
    localStorage.removeItem('etarChoice');
    sessionStorage.clear();
    localStorage.removeItem('perMonth');
  }

  setLanguage(lang: number) {
    this.layoutLanguage = lang;
    this.translocoService.setActiveLang(this.layoutLanguage === 1 ? 'pt' : 'en');
  }
}
