<ng-container *transloco="let language">
  <mat-toolbar class="footer">
    <a routerLink="/">
      <img src="assets/img/logo_adp.png" alt="logo" class="logo" />
    </a>
    <div class="footerRigth">
      <div class="social">
        <div class="socialTop">
          <div class="linked">
            <a href="https://www.linkedin.com/company/aguasdeportugal/" target="_blank">
              <img src="assets/img/linkedin.svg" alt="LinkedIn" />
            </a>
          </div>
          <div class="face">
            <a href="https://www.facebook.com/adp.aguasdeportugal/" target="_blank">
              <img src="assets/img/facebook-rect.svg" alt="Facebook" />
            </a>
          </div>
        </div>
        <div class="socialBotton">
          <div class="youtube">
            <a href="https://www.youtube.com/c/aguasdeportugal" target="_blank">
              <img src="assets/img/youtube-play.svg" alt="YouTube" />
            </a>
          </div>
          <div class="insta">
            <a href="https://www.instagram.com/adp.aguasdeportugal/" target="_blank">
              <img src="assets/img/instagram.svg" alt="Instagram" />
            </a>
          </div>
        </div>
      </div>
      <div class="localization">
        <p class="nameAdp">
          {{language('footer.adpName')}}
        </p>
        <p class="address">
          {{language('footer.address')}}
        </p>
        <p class="cp">
          {{language('footer.postalCode')}}
        </p>
      </div>
      <div class="contact">
        <p class="titleContact">
          {{language('footer.contactUs')}}
        </p>
        <p class="tel">
          {{language('footer.phone')}}
        </p>
        <p class="fax">
          {{language('footer.fax')}}
        </p>
        <p class="email">
          {{language('shared.email')}}: &#105;&#110;&#102;&#111;&#64;&#97;&#100;&#112;&#46;&#112;&#116;
        </p>
      </div>
    </div>
  </mat-toolbar>
</ng-container>