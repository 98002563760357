import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '../shared/shared.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';

@NgModule({
  declarations: [PagesComponent, NotFoundComponent],
  imports: [CommonModule, PagesRoutingModule, SharedModule, TranslocoModule],
  exports: [PagesComponent, NotFoundComponent]
})
export class PagesModule {}
