export const GlobalVariable = Object.freeze({
  listEntityMaxLength: 6
});

export const Roles = Object.freeze({
  CLIENT_ADMIN: 'CLIENT_ADMIN',
  CLIENT_ADMIN_ESTAB: 'CLIENT_ADMIN_ESTAB',
  CLIENT_CONSULTA: 'CLIENT_CONSULTA',
  ETAR_ADMIN: 'ETAR_ADMIN',
  EG_ADMIN: 'EG_ADMIN',
  ADP_ADMIN: 'ADP_ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  ALL: 'ALL'
});
