import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@Services/auth/auth.service';
import { LoaderService } from '@Services/loader/loader.service';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService, private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.url) {
          this.loaderService.setLoading(false, error.url);
        }
        if (error.url === `${environment.API_URL}/user/me` && this.authService.isAuthenticated.value) {
          this.authService.logout();
        }
        return throwError(() => new Error(error.message));
      })
    );
  }
}
