<ng-container *transloco="let language">
  <ng-container *ngIf="currentProfile$ | async as currentProfile; else noData">
    <mat-toolbar class="header">
      <div class="responsive-icon" (click)="menuResponsive()">
        <mat-icon>{{ isResponsiveMenuOpen ? 'close' : 'menu' }}</mat-icon>
      </div>
      <div class="logo-nav">
        <a [routerLink]="currentProfile.defaultLink" class="logo">
          <img src="assets/img/logo_adp.png" alt="Logo ADP Valor" />
        </a>
      </div>

      <div class="nav-menu" [ngSwitch]="currentProfile.profile">
        <ul *ngSwitchCase="'backoffice'">
          <li>
            <a routerLinkActive="active" [routerLink]="['/backoffice', 'clientes']" class="option-menu-nav">
              {{ language('navbar.clients') }}
            </a>
          </li>
          <li>
            <a routerLinkActive="active" [routerLink]="['/backoffice', 'pedidos']" class="option-menu-nav">
              {{ language('navbar.requests') }}
            </a>
          </li>
          <li>
            <a routerLinkActive="active" [routerLink]="['/backoffice', 'eg-etar']" class="option-menu-nav">
              {{ language('navbar.eg-etar') }}
            </a>
          </li>
          <li>
            <a
              routerLinkActive="active"
              [routerLink]="['/backoffice', 'utilizadores']"
              class="option-menu-nav">
              {{ language('navbar.users') }}
            </a>
          </li>
          <li>
            <a
              routerLinkActive="active"
              [routerLink]="['/backoffice', 'configuracoes']"
              class="option-menu-nav">
              {{ language('navbar.configurations') }}
            </a>
          </li>
        </ul>
        <ul *ngSwitchCase="'entity'">
          <li>
            <a
              routerLinkActive="active"
              [routerLink]="['/entidade-gestora', 'pedidos']"
              class="option-menu-nav">
              {{ language('navbar.requests') }}
            </a>
          </li>
          <li>
            <a
              routerLinkActive="active"
              [routerLink]="['/entidade-gestora', 'utilizadores']"
              class="option-menu-nav">
              {{ language('navbar.users') }}
            </a>
          </li>
          <li>
            <a
              routerLinkActive="active"
              [routerLink]="['/entidade-gestora', 'configuracoes']"
              class="option-menu-nav">
              {{ language('navbar.configurations') }}
            </a>
          </li>
        </ul>
        <ul *ngSwitchCase="'client'">
          <li>
            <a
              routerLinkActive="active"
              [routerLink]="['/pedido', 'novo']"
              *ngIf="user?.role"
              class="option-menu-nav">
              {{ language('navbar.newRequests') }}
            </a>
          </li>
          <li *ngIf="user?.role === 'CLIENT_CONSULTA'">
            <a
              [matTooltip]="language('navbar.permissionOnlyForConsultation')"
              class="option-menu-nav disabled"
              disabled>
              {{ language('navbar.newRequests') }}
            </a>
          </li>
          <li>
            <a routerLinkActive="active" [routerLink]="['/cliente', 'entregas']" class="option-menu-nav">
              {{ language('navbar.deliveries') }}
            </a>
          </li>
          <li>
            <a routerLinkActive="active" [routerLink]="['/cliente', 'utilizadores']" class="option-menu-nav">
              {{ language('navbar.users') }}
            </a>
          </li>
        </ul>
      </div>
      <div class="info">
        <div class="user">
          <div class="flex">
            <div class="identification">
              <p [tooltip]="currentProfile.type">
                {{ currentProfile.type }}
              </p>
              <p [tooltip]="currentProfile.name">
                {{ currentProfile.name }}
              </p>
            </div>
            <button (click)="logout()" class="logout semibold pointer">
              {{ language('navbar.logout') }}
            </button>
          </div>
          <div class="flex">
            <div class="set-language">
              <a
                class="set-lang language-set-pt"
                (click)="setLanguage(1)"
                [ngClass]="{ 'active-language': layoutLanguage === 1 }">
                PT
              </a>
              |
              <a
                class="set-lang language-set-en"
                (click)="setLanguage(2)"
                [ngClass]="{ 'active-language': layoutLanguage === 2 }">
                EN
              </a>
            </div>
            <span>
              <mat-icon [routerLink]="['/backoffice', 'dashboard']" class="iconDashboard pointer">
                dashboard
              </mat-icon>
            </span>
          </div>
        </div>
      </div>
    </mat-toolbar>
  </ng-container>
  <ng-template #noData>
    <mat-toolbar class="header">
      <div class="logo-nav">
        <span class="logo">
          <img src="assets/img/logo_adp.png" alt="Logo ADP Valor" />
        </span>
      </div>
    </mat-toolbar>
  </ng-template>
</ng-container>
