import { ExportXlsxFileService } from '@Services/export-xlsx-file.service';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ChartBoxComponent } from './charts/chart-box/chart-box.component';
import { ClientChartComponent } from './charts/client/client.component';
import { EtarDetailsChartComponent } from './charts/etar-details/etar-details.component';
import { PercentOfTotalComponent } from './charts/percent-of-total/percent-of-total.component';
import { VerticalBarComponent } from './charts/vertical-bar/vertical-bar.component';
import { ConfirmRequestRescheduleComponent } from './components/confirm-request-reschedule/confirm-request-reschedule.component';
import { EgarRegistrationComponent } from './components/egar-registration/egar-registration.component';
import { EtarTabsComponent } from './components/etar-tabs/etar-tabs.component';
import { LastUpdatedDateComponent } from './components/last-updated-date/last-updated-date.component';
import { PedidosDetalheSolicitacaoDeDocumentosComponent } from './components/pedidos-detalhe-solicitacao-de-documentos/pedidos-detalhe-solicitacao-de-documentos.component';
import { RequestStepsComponent } from './components/request-steps/request-steps.component';
import { RescheduleDeliveryComponent } from './components/reschedule-delivery/reschedule-delivery.component';
import { ReschedulingEntityConfirmComponent } from './components/rescheduling-entity-confirm/rescheduling-entity-confirm.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { ContractDocumentComponent } from './files/contract-document/contract-document.component';
import { ContractTemplateComponent } from './files/contract-template/contract-template.component';
import { UploadEtarConditionsRegulationComponent } from './files/upload-etar-conditions-regulation/upload-etar-conditions-regulation.component';
import { UploadScreenComponent } from './files/upload-screen/upload-screen.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ModalComponent } from './modal/modal.component';
import { PipeModule } from './pipe.module';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    RequestStepsComponent,
    ChartBoxComponent,
    PercentOfTotalComponent,
    EtarDetailsChartComponent,
    ClientChartComponent,
    DragDropDirective,
    ContractDocumentComponent,
    ContractTemplateComponent,
    UploadEtarConditionsRegulationComponent,
    UploadScreenComponent,
    VerticalBarComponent,
    PedidosDetalheSolicitacaoDeDocumentosComponent,
    ReschedulingEntityConfirmComponent,
    RescheduleDeliveryComponent,
    LastUpdatedDateComponent,
    EgarRegistrationComponent,
    ConfirmRequestRescheduleComponent,
    ModalComponent,
    EtarTabsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatToolbarModule,
    MatIconModule,
    TooltipModule.forRoot(),
    MatTooltipModule,
    NgxDropzoneModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    ReactiveFormsModule,
    TranslocoModule,
    FullCalendarModule,
    PipeModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    RequestStepsComponent,
    ChartBoxComponent,
    PercentOfTotalComponent,
    EtarDetailsChartComponent,
    ClientChartComponent,
    DragDropDirective,
    ContractDocumentComponent,
    ContractTemplateComponent,
    UploadEtarConditionsRegulationComponent,
    UploadScreenComponent,
    VerticalBarComponent,
    PedidosDetalheSolicitacaoDeDocumentosComponent,
    ReschedulingEntityConfirmComponent,
    RescheduleDeliveryComponent,
    LastUpdatedDateComponent,
    EgarRegistrationComponent,
    ConfirmRequestRescheduleComponent,
    ModalComponent,
    EtarTabsComponent,
    PipeModule
  ],
  providers: [ExportXlsxFileService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [ModalComponent]
})
export class SharedModule {}
