import { User } from '@Models/client/user-model';
import { AuthService } from '@Services/auth/auth.service';
import { LoaderService } from '@Services/loader/loader.service';
import { UtilService } from '@Services/util.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, delay } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loading$!: Observable<boolean>;
  user: User = new User();
  isMobile = this.deviceService.isMobile();

  constructor(
    public loaderService: LoaderService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private utilService: UtilService,
    private translocoService: TranslocoService
  ) {
    if (this.authService.isAuthenticated.value) {
      this.authService.loadingUserAndTokenRoutine();
    }
    this.translocoService.load('pt');
  }

  ngOnInit(): void {
    this.listenToLoading();
    if (this.isMobile && !window.location.href.split('/').includes('mobile')) {
      const role = localStorage.getItem('role');
      if (this.utilService.isEgAdmin(role ?? '') || this.utilService.isEtarAdmin(role ?? '')) {
        this.router.navigate(['/mobile']);
      } else {
        this.authService.logout();
      }
    }
  }

  replyUser(result: User) {
    this.user = result;
  }

  listenToLoading(): void {
    this.loading$ = this.loaderService.loaderSubject.pipe(delay(0));
  }
}
