import { Etar } from '@Models/entity/etar-model';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private _headerData = new BehaviorSubject<{ title: string }>({
    title: ''
  });

  constructor(private readonly title: Title) {}

  get headerData(): { title: string } {
    return this._headerData.value;
  }

  set headerData(headerData: { title: string }) {
    this._headerData.next(headerData);
    if (headerData.title) {
      this.title.setTitle(`AdP Valor | ${headerData.title}`);
    }
  }

  getDaysOfWeekBetweenDates(start: Date, end: Date, dayName: number) {
    const result = [];

    start.setDate(start.getDate() + ((dayName - start.getDay() + 7) % 7));
    while (start <= end) {
      result.push(new Date(+start));
      start.setDate(start.getDate() + 7);
    }
    return result;
  }

  getDaysOfMonthBetweenDates(start: Date, end: Date, numberDay: number) {
    const result = [];
    const current = new Date(start.getFullYear(), start.getMonth(), numberDay);
    while (current <= end) {
      if (current >= start && current <= end) {
        result.push(new Date(+current));
      }
      current.setMonth(current.getMonth() + 1);
    }
    return result;
  }

  getDaysBetweenDates(start: Date, end: Date) {
    const result = [];
    const momentStartDate = moment(start);
    const momentEndDate = moment(end);

    while (momentStartDate.isSameOrBefore(momentEndDate)) {
      result.push(momentStartDate.toDate());
      momentStartDate.add(1, 'days');
    }
    return result;
  }

  isAdpAdmin(role?: string) {
    if (role === 'ADP_ADMIN' || role === 'SUPER_ADMIN') {
      return true;
    }
    return false;
  }

  isSuperAdmin(role?: string) {
    if (role === 'SUPER_ADMIN') {
      return true;
    }
    return false;
  }

  isEgAdmin(role?: string) {
    if (role === 'EG_ADMIN') {
      return true;
    }
    return false;
  }

  isEtarAdmin(role?: string) {
    if (role === 'ETAR_ADMIN') {
      return true;
    }
    return false;
  }

  isClient(role?: string) {
    if (role === 'CLIENT_ADMIN') {
      return true;
    }
    return false;
  }

  isClientEstablishment(role?: string) {
    if (role === 'CLIENT_ADMIN_ESTAB') {
      return true;
    }
    return false;
  }

  getUniqueListBy<T>(arr: any[], key: string): T[] {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  percentage(num: number, per: number) {
    return (num / per) * 100 ? ((num / per) * 100).toFixed(0) : '0';
  }

  createDaysAvailable(etar: Etar): string {
    const { monday, tuesday, wednesday, thursday, friday, sunday, saturday } = etar;

    const weekDays = [
      { string: '2ª', isAvailable: monday },
      { string: '3ª', isAvailable: tuesday },
      { string: '4ª', isAvailable: wednesday },
      { string: '5ª', isAvailable: thursday },
      { string: '6ª', isAvailable: friday },
      { string: 'Sábado', isAvailable: saturday },
      { string: 'Domingo', isAvailable: sunday }
    ];

    const isWorkDays = monday && tuesday && wednesday && thursday && friday;

    if (isWorkDays && !saturday && !sunday) {
      return '2ª a 6ª Feira';
    } else if (isWorkDays && saturday) {
      return '2ª a Sábado';
    } else if (isWorkDays && sunday) {
      return '2ª a 6ª Feira e Domingo';
    } else {
      let totalDays = '';
      const daysAvailable = weekDays.filter(days => days.isAvailable);

      daysAvailable.forEach((day, index) => {
        if (index === 0) {
          totalDays = day.string;
        } else if (index === daysAvailable.length - 1) {
          totalDays = `${totalDays} e ${day.string} Feira`;
        } else {
          totalDays = `${totalDays}, ${day.string}`;
        }
      });

      return totalDays;
    }
  }

  createHourDateByNumber(startHour: string, endHour: string): string {
    if (startHour.length === 3) {
      startHour = `0${startHour}`;
    }
    if (startHour.length === 3) {
      endHour = `0${endHour}`;
    }
    const startHours = startHour.slice(0, 2);
    const startMinutes = startHour.slice(2, 4);
    const endHours = endHour.slice(0, 2);
    const endMinutes = endHour.slice(2, 4);
    return `| ${startHours}:${startMinutes}-${endHours}:${endMinutes}`;
  }
}
