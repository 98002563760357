import { FilteredList } from '@Models/client/establishment-model';
import {
  CreateUserBackOffice,
  User,
  UserEdit,
  UserEditStatus,
  UserNotificationsEdit
} from '@Models/client/user-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) {}

  apiUrl = environment.API_URL;

  getUserListAll(): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiUrl}/user/find-all`);
  }

  getUserById(): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/user/me`);
  }

  getUserId(id: number): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/user/find-by-id/${id}`);
  }

  createUser(user: User): Observable<any> {
    return this.http.post<User>(`${this.apiUrl}/user/create`, user);
  }

  editUser(user: UserEdit, id: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/user/update-user/${id}`, user);
  }

  editUserNotifications(userEdit: UserNotificationsEdit, id: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/user/update-notifications/${id}`, userEdit);
  }

  editUserStatus(userEdit: UserEditStatus, id: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/user/update-user-status/${id}`, userEdit);
  }

  softdeleteUser(modelDelete: User, id: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/user/softdelete-user/${id}`, modelDelete);
  }

  getUserfilteredList(fildes: FilteredList): Observable<any> {
    return this.http.post<User[]>(`${this.apiUrl}/user/filtered-list?max=1000000`, fildes);
  }

  editUserBackOffice(user: User, id: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/user/update-user/${id}`, user);
  }
  createUserBackOffice(user: CreateUserBackOffice): Observable<any> {
    return this.http.post<User>(`${this.apiUrl}/user/create`, user);
  }
}
