import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@Services/auth/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignInGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.authService.isAuthenticated.value && !state.url.includes('sign-in')) {
      this.redirectSignIn();
      return false;
    }
    if (this.authService.isAuthenticated.value && state.url === '/') {
      this.authService.redirectCurrentRole();
      return true;
    }
    if (!this.authService.isAuthenticated.value && state.url === '/') {
      this.redirectSignIn();
      return false;
    }
    return true;
  }
  private redirectSignIn() {
    this.router.navigate(['auth', 'sign-in']);
    return false;
  }
}
